const popup = document.getElementById('announcement-popup');
const closeBtn = document.getElementById('close-popup-announcement');

// Vérifie si le compteur d'affichage de la popup existe déjà dans le localStorage
window.onload = function() {
    let displayCount = localStorage.getItem('announcementPopupDisplayCount');

    // Initialiser le compteur à 0 s'il n'existe pas encore
    if (!displayCount) {
        displayCount = 0;
    }

    // Si le compteur est inférieur à 3, on affiche la popup
    if (displayCount < 3) {
        popup.style.display = 'flex';
        // Incrémente le compteur et met à jour le localStorage
        displayCount++;
        localStorage.setItem('announcementPopupDisplayCount', displayCount);
    }
};

closeBtn.onclick = function() {
    popup.style.display = 'none';
};

window.onclick = function(event) {
    if (event.target === popup) {
        popup.style.display = 'none';
    }
};
